<template>

  <div class="cta_main_view w-100">

    <div class="view_inner d-flex flex-column">
      <div class="view_center">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active" v-if="getCampaignAdd.campaign_type.retargeting"></span>
          <span class="dotted_circle active"></span>
        </div>

        <b-dropdown right class=" dropdown-menu-right default_style_dropdown checkbox_dropdown"
                    ref="custom_widget_list"
                    :no-caret="true">
          <div class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
               slot="button-content">
            <span class="text">Select your Custom Widget</span>
            <span class="arrow_icon ml-auto">
                            <i class=" fal fa-angle-down ml-3"></i>
                         </span>

          </div>
          <ul class="clear inner ">

            <template v-if="getCustomWidgetList.length > 0">
              <template v-if="getCustomWidgetList && getCustomWidgetList.length > 7">
                <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation">
                  <input placeholder="Search for custom widget"
                         style="padding-left: 37px"
                         type="text"
                         class="search_input_block_for_dropdown dropdown_header with-shadow rounded_shape"
                         v-model="search_query" data-cy="search-camp"> <i
                  class="fal fa-search"></i>
                </div>
                <hr>
              </template>
              <template v-if="getSortedCustomWidgetList && getSortedCustomWidgetList.length">
                <li v-for="code in getSortedCustomWidgetList" class="list_item_li">

                  <div class="checkbox_input_image" @click="$refs.custom_widget_list.hide()">
                    <input v-model="widgetCode" type="checkbox"
                           :id="code._id"
                           :value="code._id">
                    <label class="checkbox_right with_checked_icon" :for="code._id">
                      {{ code.name }}
                    </label>
                  </div>
                </li>
              </template>
              <template v-else>
                <li class=" no_text_li">
                  {{ searchFiltersMessages().search_field_dropdown }}
                </li>
              </template>
            </template>
            <li class="no_text_li" v-else>
              {{ searchFiltersMessages('custom widget').empty_list_dropdown }}
            </li>
          </ul>
        </b-dropdown>

        <div class="or_separator">
          <span class="sep_text">OR</span>
        </div>

        <template v-if="!getCustomWidgetAdd.addCustomCodeCheck">

          <button class="d-block btn---cta btn-border btn-round  btn-bold w-100"
                  @click.prevent="$store.commit('SET_ADD_CUSTOM_WIDGET_FROM_CTA', true)">
            <span>Add a custom widget code</span>
          </button>

        </template>
        <template v-else>
          <custom-widget-add-form :validations="validations"
                                  :messages="messages"
                                  :cmOptions="cmOptions"
                                  location="campaign-steps">

          </custom-widget-add-form>

          <div class="">

            <button class="btn---cta  btn-blue btn-bold btn-round" @click.prevent="handleAddWidget()">
              <span>Add</span>
            </button>
            <button class="btn---cta  light-blue btn-bold btn-round"
                    @click.prevent="$store.commit('SET_ADD_CUSTOM_WIDGET_FROM_CTA', false)">
              <span>Close</span>
            </button>
          </div>
        </template>

        <div class="clearfix"></div>

        <div v-if="getCampaignAdd.script_ids && getCampaignAdd.script_ids.length > 0"
             class="retargeting-list social_icons_square   checkbox-list  ">
          <p class="list-title">Added Custom Widgets</p>
          <div v-for="code in getCampaignAdd.script_ids" class="list-item d-flex align-items-center">
            <p class="text d-flex align-items-center">

                    <span class="icon">
                      <i class=" far fa-code"></i>
                    </span>
              <span>{{ getCustomCodeData(code, 'name') }}</span>
            </p>
            <i class="fal fa-times ml-auto remove-icon" v-tooltip.top-center="'Remove'"
               @click.prevent="$store.commit('REMOVE_FROM_CAMPAIGN_CODE_WIDGET_LIST', code)"></i>
          </div>

        </div>

        <!--<div v-if="getCampaignAdd.script_ids && getCampaignAdd.script_ids.length > 0"-->
        <!--class=" onboardingForm basic_form cta_form">-->
        <!--<div class="input_field col-md-12 retargeting_added_list ">-->
        <!--<ul>-->
        <!--<li v-for="code in getCampaignAdd.script_ids">-->
        <!--<div class="detail">-->
        <!--<span class="icon">-->
        <!--<i class=" fa fa-code"></i></span>-->
        <!--{{getCustomCodeData(code,'name')}}-->
        <!--</div>-->

        <!--<div class="option">-->

        <!--<span class="icon red" v-tooltip.top-center="'Remove'"-->
        <!--@click.prevent="$store.commit('REMOVE_FROM_CAMPAIGN_CODE_WIDGET_LIST', code)">-->
        <!--<i class="fal fa-times "></i></span>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->
        <!--</div>-->

      </div>

      <div class="view_bottom">
        <div class="view_bottom_inner text-center">

          <button v-if="getCampaignAdd.campaign_type.retargeting"
                  @click="$router.push({ 'name': 'retargeting' })" class="btn---cta light-blue btn-round">
            <span>Go back</span>
          </button>
          <button v-else
                  @click="$router.push({ 'name': 'campaign_type' })" class="btn---cta light-blue btn-round">
            <span>Go back</span>
          </button>

          <button :disabled="getCampaignLoaders.store" @click.prevent="handleIntegrationCampaign()"
                  class="btn---cta btn-blue d-inline-flex btn-round">
            <span>{{ getCampaignButtonText() }}</span>
            <clip-loader v-if="getCampaignLoaders.store" class="ml-1" :size="size" :color="color"></clip-loader>
          </button>

        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import CustomWidgetAddForm from '@/views/pages/setting/custom-widget/forms/CustomWidgetAddForm'
import Dropdown from '@/ui/Dropdown.vue'
import {widgetCMOptions, widgetMessages, widgetValidations, searchFiltersMessages} from '@/common/attributes'
import {campaignTypes, widgetTypes} from '@/state/modules/mutation-types'
import orderBy from 'lodash.orderby'

export default ({
  components: {
    CustomWidgetAddForm,
    Dropdown
  },
  data () {
    return {
      size: '14px',
      color: '#fff',
      validations: widgetValidations,
      messages: widgetMessages,
      cmOptions: widgetCMOptions,
      search_query: '',
      searchFiltersMessages: searchFiltersMessages
    }
  },
  created () {
    if (!this.getCampaignAdd.brand_id) {
      this.$router.push({name: 'create'})
    }
  },

  computed: {

    ...mapGetters([
      'getCampaignAdd',
      'getCustomWidgetList',
      'getCustomWidgetAdd',
      'getCampaignLoaders'
    ]),
    widgetCode: {
      get () {
        return this.getCampaignAdd.script_ids
      },
      set (value) {
        this.getCampaignAdd.script_ids = value
      }
    },
    getSortedCustomWidgetList () {
      if (this.search_query && this.search_query.trim().length > 0) {
        let customWidget = this.getCustomWidgetList.filter(item => item.name.toLowerCase().includes(this.search_query.toLowerCase()))
        return orderBy(customWidget, 'name')
      }
      return orderBy(this.getCustomWidgetList, 'name')
    }

  },

  methods: {
    ...mapActions([]),
    async handleAddWidget () {
      this.validations.name = this.requiredCheck(this.getCustomWidgetAdd.name)
      if (this.requiredCheck(this.getCustomWidgetAdd.js) && this.requiredCheck(this.getCustomWidgetAdd.html)) {
        this.alertMessage('Please enter the HTML or Javascript tag.', 'error')
        return
      }
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        let res = await this.$store.dispatch('storeWidget')
        if (res) {
          this.$store.commit(campaignTypes.ADD_TO_CAMPAIGN_CODE_WIDGET_LIST, res._id)
          this.$store.commit(widgetTypes.SET_ADD_CUSTOM_WIDGET_FROM_CTA, false)
        }
        // this.$store.dispatch('storeDomain')
      }
    },
    validateScriptsAdded () {
      if (this.getCampaignAdd.script_ids.length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add third-party scripts to create a campaign.',
          type: 'error'
        })
        return false
      }
      return true
    },
    async handleIntegrationCampaign () {
      this.checkCampaignValidation()
      if(this.getCampaignAdd.campaign_type.retargeting && this.getCampaignAdd.retargeting_ids.length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add retargeting pixels to create a campaign.',
          type: 'error'
        })
        return
      }

      let res = await this.validateScriptsAdded()
      if (res) {
        // mixin method
        this.storeCampaign()
      }
    }
  },
  watch: {}

})
</script>
